body.page-id-11 {

  .big-image.grid__content-item{
    margin-bottom: 0;

    overflow: visible;
   img{
      // -webkit-box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
      // -moz-box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
      box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
    }

  }

  /* ISOTOPE * /

/**** Isotope Filtering ****/

  .isotope-item {
    z-index: 2;

  }

  .isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
  }

  /**** Isotope CSS3 transitions ****/

  .isotope,
  .isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
  }

  .isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
  }

  .isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: top, left, opacity;
    transition-property: transform, opacity;
  }

  #gallery-grid {
    // width:990px;
    width: 100%;
    // padding:0 #{map-get($responsivegap, "base")} 0 #{map-get($responsivegap, "base")};
    box-sizing: border-box;
    // margin-top:44px;
    display: block;
    z-index: 0; //to resolve dev grid - delet/review if need

    position: relative;
    overflow: hidden;
    box-sizing: content-box;
  }

  .single {
    // isotope item
    overflow:visible; // to hide the scaling
    position: relative;
    display: block;
    height: auto;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    margin-bottom:#{map-get($responsivegap, "base")};
    box-sizing: content-box;
  }

  .inner-single {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
  }

  .gallery-header {
    position: relative;
  }

  .gRight {
    align-items: end;
    display: flex;
    width: 100%;
    justify-content: right;
    position: absolute;
    right: 0;

    span {
      position: relative;
      display: block
    }

    a {
      color: $orange;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    display: none;
    cursor: pointer;

    .overlay-inner {
      display: grid;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 14px;
      align-content: center;
      justify-content: center;
      margin: auto;
      padding:5%;// to ensure text not to close to edge - review value iff need
      align-items: center;
      position: absolute;
    }
  }

  #gallery hr.all {
    width: 100%;
    margin: 44px 0px;
  }



  .gallery-filters {
    flex-flow: column;


    #filters,
    #filters-colour {
      // width:476px;
      // float:left;
      justify-content: center;
    }

    @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {}

    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
      flex-flow: row;


      #filters {
        justify-content: flex-start;

      }

      #filters-colour {
        justify-content: flex-end;
      }
    }

    ul {
      display: flex;
      // padding-bottom: 24px;
      padding-bottom: 24px;
      width: 100%;

      li {
        margin-right: #{map-get($responsivegap, "base")};
        display: flex;

        &.current {
          color: $orange
        }

        &:last-child {
          margin-right: 0;
        }
      }

    }
  }



  // .gallery-filters li {
  //   // float: left;

  //   // padding-right: 16px; // review
  // }

  // #filters-colour li {
  //   // float: right;
  // //  float: left;
  //   // padding-left: 16px;
  // }

  // #filters li a.current,
  // #filters-colour li a.current {

  // }

  // not all with have rotations -- class being applied accordignly

  #gallery-grid {

    .img-wrap {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      float: left;
    }

    .placeholder {
      position: relative;
      top: 0;
      left: 0;
      opacity: 0;
    }

    .rotate {
    //  transform: rotate(-90deg) translate(-100%, 0%); // I think i've got the orientatin wrong - 
    //  transform-origin: top left; // I think i've got the orientatin wrong - 
      //rotation corrected:
      transform: rotate(90deg) translate( 100%, 0%);
      transform-origin: top right;
      position: absolute;

      // this fixes teh gaps - review if futher issues arise
      left: 0;
      right: 0;
    }

    img {
      display: block;
      width: auto;
      height: 100%;

      &.rotate {
        // transform: rotate(-90deg) translate(-100%, 0%);
        // // transform-origin: top left;
        // background-color: red;
        // transform: rotate(90deg);
      }

      // float: left;
      // margin: auto;
      // position: relative;

    }

    img {
      // ensure to have no "svs" rules set on this components
      display: block;
      width: 100%;
      height: auto;
      // scale: 1.1; // to hide the white space
    }


  }

  .grid-sizer,
  .gutter-sizer {
    height: 0;
  }

  .gutter-sizer {

    @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
      // width: 33.33%; // reveiw
      width: 5%;
    }

    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
      // width:5%;
      width: 2.5%;
    }

    @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
      // width: 10%; // reveiw
      width: 2.5%;

    }

  }

  .grid-sizer,
  .single //istope items 

    {
    display: block;
    width: 100%;

    @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
      // width: 33.33%; // reveiw
      width: 30%; // reveiw
    }

    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
      // width: 20%; // reveiw
      // width: 16%;
      width: 18%;
    }

    @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
      // width: 10%; // reveiw
      // width: 7.75%; // this maaynot work on certain browser - chekc safair + fff

    }

  }

}