.logo {
    img {
        width: 100%;
    }
}

//.footer__logo
.hover-icon,
.icon,
.logo {

    svg {
        width: 100%;
        height: 100%;
        display: flex;
    }

}

.hover-icon {

    svg {}
}

.svg-icon {

    &.\--twitter {}
}