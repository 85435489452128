body {
  background-color: #f2f2f2;
  color: #404040;
  font-family: $font-stack;
  font-size: 12px; // review this - start flexible type after 5 col breakpoint?
  line-height: 18px;

  &.grid__gridwidth--active{
  @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
    font-size: calc(responsivetype('4'));
    line-height: calc(1.5*responsivetype('4'));
  }
}
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

p {
  padding-bottom: 18px;
}

a {
  color: #404040;
  text-decoration: none;
}

a:hover {
  color: #dc7226;
}

.title {
  font-size: 14px;

  .grid__gridwidth--active & {
  @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
    font-size: calc(responsivetype('3'));
    line-height: calc(1.5*responsivetype('3'));
  }
}
}

.post-title {
  text-transform: uppercase;
}

.caption {
  font-size: 10px;

  .grid__gridwidth--active & {
  @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
    font-size: calc(responsivetype('5'));
    line-height: calc(1.5*responsivetype('5'));
  }
}
}

.orange {
  color: #dc7226;
}

.justify {}

.more-link {
  font-style: italic;
  font-size: 10px;

  .grid__gridwidth--active & {
  @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
    font-size: calc(responsivetype('5'));
    line-height: calc(1.5*responsivetype('5'));
  }
}

}

hr {
  width: 32px;
  height: 1px;
  border: none;
  background-color: #404040;
  margin: 0px;
}