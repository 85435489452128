body.single-gallery {

  .rug-nav {
    justify-content: space-between;
  }

  .rug-image {

    max-width: initial;
    // max-height: 30vh;// review
    // background-color: red;
    box-shadow: none;
    background:none;
    overflow: visible;

    img {
         background-color: #f2f2f2;
      box-shadow: 1px 3px 5px rgb(0 0 0 / 20%);
      width: 100%;
      height: auto;
      // background-color: green;
      &.vertical {
        height: 100%;
        width: auto;
        margin: auto;
        max-width: 100%;
        // background-color: yellow;
      }
    }

  }

  .thumb-scroller {
    // height: auto;
    // height: 30vh;// review - but maybe I need to set a height (view port) and based evertyhing of taht

  }

  .thumb-container {
    //these rules set to ensure height always 100% - review - esepcially if isuses arise with the js plugin
    // height: 100%;
    // display: block;

  }

  .rotate {
    // transform: rotate(-90deg) translate(-100%, 0%);
    //corrected rotation:
    transform: rotate(90deg) translate( 100%, 0%);
    transform-origin: top right;
    position: absolute;

    
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;

    img {
      // width:auto;
      // height:100%;
      width: 100%;
      height: auto;
    }
  }

  .thumb {
    // margin: 0px 38px 38px 0px;
    position: relative;
    float: left;
    display: block;

    &.will-rotate {
      // opacity: 0.5;
      // overflow: hidden;

      .placeholder {
        position: relative;
        opacity: 0;
        height: 100%;
        width: auto;
      }

      .inner-thumb {
        // width: auto;
      }

    }

    .inner-thumb {
      position: relative;
      height: 100%;
      width: 100%;
      display: block;
      overflow: hidden;
    }

  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    display: none;
    cursor: pointer;
    align-content: center;
    justify-content: center;

    .overlay-inner {
      display: grid;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 14px;
      align-content: center;
      justify-content: center;
      margin: auto;
      padding: 5%; // to ensure text not to close to edge - review value iff need
      align-items: center;
      position: relative; // changed from abssolute to ensure text is centered
    }
  }

}