.rug-image {
	position: relative;
	// width:952px;
	width: 100%;
	// max-width: 952px; // old measurement;;
	background-color: #f2f2f2;
	-webkit-box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
	margin-bottom: 25px;
	overflow: hidden;
}

.thumbs {
	// background-color: red; //reivew all thsese rules:
	// width:952px;
	width: 100%;
	// max-width: 952px;// old measurement;;
	position: relative;
	padding-top: 7px;
}

.space {
	padding-bottom: 44px;
}

.thumbs .rule {
	width: 100%;
	height: 1px;
	background-color: $black;
	position: absolute;
	bottom: 8px;
}

.thumb-scroller {
	height: auto;
	// height:320px;
	overflow: hidden;
	float: left;
	flex-basis: 50%; // review at smaller sizes


	@media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
		flex-basis: 60%; // review
	}

	@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
		flex-basis: 70%; // review
	}


	// @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
	// 	flex-basis: 90%; // review
	// }

}

// .jspHorizontalBar{
// 	z-index: 3;
// }

#commissions .thumb-scroller {
	// width:952px;
	width: 100%;
	// max-width: 952px;// old measurement;
	// height:615px;// reivew
}

// #gallery .thumb-scroller {
// 	width:754px;
// }

// .thumb-container {
// 	height:320px;
// }

.thumb {
	margin: 0px 38px 38px 0px;
	position: relative;
	float: left;
}

.thumb img {
	-webkit-box-shadow: 3px -1px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 3px -1px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 3px -1px 5px rgba(0, 0, 0, 0.2);
}

// .thumb .name, 
// .commissions-thumb .name {
// 	position:absolute;
// 	left:0px;
// }

.thumbs .info {
	// width:160px;
	float: right;
	flex-basis: 50%; // review
	padding-left: #{map-get($responsivegap, "base")};

	@media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
		flex-basis: 40%; // review
	}

	@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
		flex-basis: 30%; // review
	}

	// @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
	// 	flex-basis: 10%; // review
	// }


}

.thumbs .info-wall {
	// width:160px;
	// float: left;
}

.thumbs .rotate {
	position: relative;
}

.rug-nav {
	// width:952px;
	width: 100%;
	// max-width: 952px; // old measurement;;
	// margin-bottom: 8px;
	// font-size: 10px;
	margin-bottom: calc(0.5* map-get($responsivegap, "base"));
	font-size: 9px;
	line-height: 14px;

	&.grid__gridwidth--active {

		@media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
			font-size: calc(responsivetype('3'));
			line-height: calc(1.5*responsivetype('3'));
		}
	}

}