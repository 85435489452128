body.page-id-7 {

    .press-carousel {
        overflow: auto;
        background-color: #f2f2f2;
        box-shadow: 1px 3px 5px rgb(0 0 0 / 20%);
        width: 100%;
        height: auto;
        position: relative;
        display: block;
        min-height: 60vh;
    }

    /* PRESS */

    // #press .big-image {
    //     // width: 536px;
    //     width: 100%;
    //     height: auto;
    //     position: relative;
    //     display: block;
    //     // reivew this 
    //     // height:720px;
    //     // float: right;
    //     cursor: pointer;
    // }

    .inline-caption {
        position: relative;
    }
    
   .image-caption {
        // review
        position: absolute;
        left: 0px;
        bottom: 0;
        // bottom: 199px; // review
    }

    // #press .fader,
    // #press .fader li {
    //     position: relative;
    //     // width:536px;
    //     // height:720px;
    // }

    // #press .first {
    //     // width: 300px;
    //     float: left;
    // }

    #pressBig {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background-color: rgba(0, 0, 0, 0.8);
        display: none;
        z-index: 1000;
        cursor: pointer;
    }

    #pressBig .gallery {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        justify-content: center;
        align-items: center;
    }

    #pressBig .gallery li {
        position: absolute;
        // left:50%;
        // top:50%;
        display: none;

        // z-index: 1001;
    }

    #pressBig .gallery li.on {
        z-index: 1002; // this far to omuch!
        width: auto; // how well supported is this?
        height: auto; //dito
        justify-content: center;
        align-items: center;
        margin: auto;

    }

}