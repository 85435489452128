	/* FOOTER */

	#footer {
		background-color: $white;
		box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
		font-size: 9px;
		line-height: 14px;

		.grid__gridwidth--active &{

		@media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
			font-size: calc(responsivetype('6'));
			line-height: calc(1.5*responsivetype('6'));
		}
		}
	}

	.footer-cont {
		max-width: map-get($sizes, "wrapperfixed"); // review
		margin: auto;
		text-align: center;
		padding-bottom:#{map-get($responsivegap, "base")};

		.grid__gridwidth--active &{
			max-width: map-get($sizes, "wrapper"); // revuew	
		}
	

		@media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
			text-align: right;
		}

	}

	#footer .all {
		// float:left;
		// // width:952px;
		// width: 100%;
		// max-width: 952px;// old measurement;;
		// margin-bottom:13px;
	}

	#footer .header {
		justify-content: center;
		margin-top:#{map-get($responsivegap, "base")};


		@media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
			justify-content: left;
		}

	}

	#footer .col {
		padding-right:calc(0.5 * #{map-get($responsivegap, "xxs")}); //reivew
		margin-right:calc(-0.5 * #{map-get($responsivegap, "xxs")}); //reivew

		&:last-child {
			border-right: none; // ensure not border on last item
		}

		@media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
			border-right: solid #c1c1c1 1px; //review
			text-align: left;

			&:nth-child(4) {
				border-right: none; // ensure not border on outer items
			}

		}

		@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
			grid-column: span 1;

			&:nth-child(4) {
				border-right: solid #c1c1c1 1px; //review
			}

		}

		@media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
			grid-column: span 2;
		}
	}

	#footer .plus {
		// font-size: 10px;
		// line-height: 15px;

		// @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
		// 	font-size: calc(responsivetype('5'));
		// 	line-height: calc(1.5*responsivetype('5'));
		// }
	}

	.mc_merge_var{
		margin-bottom: 4px;

	}

	.mc_var_label {
		display: none;
	}

	.mc_input {
		//	width:158px;
		width: 100%;
		border: 1px #c1c1c1 solid;
	}

	.mc_input:focus {
		border: 1px #929292 solid;
		outline: none;
	}

	#mc-indicates-required{
		display: none;

	}

	.mc_error_msg{
		color: #dc7226;
	}

	#mc_signup_submit {
		border: none;
		background-color: transparent;
		color: #434040;
		// font-family: Georgia, "Times New Roman", Times, serif;
		// font-size: 10px;
		// line-height: 18px;
		padding: 0px;
		margin: 0px auto 0px auto!important;
		cursor: pointer;
		width: 80%;
		display: block;

		&:hover{
			color: #dc7226;
		}


	}

	.social {

		.social-icons {
			align-items: center;
			justify-content: center;
			gap:#{map-get($responsivegap, "base")};

			@media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
				justify-content: flex-start;
			}
		}

		.social-icon {
			display: flex;

			&:last-child {
				margin-right: 0;
			}

			img {
				max-width: calc(1.5* map-get($responsivegap, "base"));
				// review
			}
		}

	}