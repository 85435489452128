* {
  box-sizing: border-box;
}

.image-captions {
  background: #FAFAFA;
  margin: 0;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: inherit;
  width: 100%;
  text-align: left;
}

.flickity-page-dots {
  bottom: 0;
  z-index: 3; //above captions
  left: 50%;
  right: 0;
  display: flex;
  justify-content: right;
  width: auto;
  // height: 20px;
  height: 38px; // review
  padding: 10px;
  align-items: center;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background: $grey-dark;
  border-radius: 50%;
  cursor: pointer;

  &.is-selected {
    background: $orange;
  }

}