
body.page-id-15{


.big-image{

  .grid__content-item{
    -webkit-box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
    box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
  }
}


.two-cols{
  display: block; 
  @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
  column-count: 2;
  display: block;
  }
 
  p{
 
    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
    
    }
  }
 

  img.wp-image-2134{
    width: 50%;
    margin: auto;
   
    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {

      // flex-basis:50%;
      margin:0;
      width: 50%;
    }
  }
  
}

}