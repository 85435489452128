body.home {

 

  .home-carousel {
    background: #FAFAFA; //review var
    width: 100%;
    height: auto;  
  }
  
  
  .home-carousel {
    background-color: #f2f2f2;
    -webkit-box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    padding-bottom: 58px;
  
    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
    padding-bottom:38px;

    }
    @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
      height: auto;
      // max-height: 50vh;
      }


    .slide{
      width: 100%;
      height: auto;
    }
 
    .image-captions {
     text-align: center;
      bottom: 20px; //
  
      @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
        bottom: 0px; //
        text-align: left;
      }
  
    }
  
    .flickity-page-dots {
      position: absolute;
      left: 0%;
      justify-content: center;
      width: 100%;
      height: 30px;
      padding: 10px;
      align-items: center;
      background: #fafafa;
  
      @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
        position: absolute;
        justify-content: flex-end;
        background: transparent;
        height: 38px;
      }
  
    }
  
  }



  .flex-module-wrap {

    gap: map-get($responsivegap, "base");
    display: flex;
    flex-flow: column;
    height: 100%;


    @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
      flex-flow: wrap row;
    }

    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
     margin-left: 5%;// this add some arbituary space ;
    }

    .gallery-col {
      // flex-basis: 100%;
      width: 100%;
      min-height: 0;

      @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
        // flex-basis: 60%;
        width: calc(60% - (map-get($responsivegap, "base")/2));
      }

    }

    .more {
      // thrid col
      display: flex;
      width: 100%;
      // flex-basis: 100%;
      min-height: 0;
      flex-grow: 1;
      gap: map-get($responsivegap, "base");


      @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
        // flex-basis: 40%;
        width: calc(40% - (map-get($responsivegap, "base")/2));

      }

      a{
        flex-grow: 1;
        min-height: 0;
        flex-flow: column;
        display: flex;
        width: 100%;
      }




    }
    .homethumb {
      flex-grow: 1;
      min-height: 0;
      flex-flow: column;
      display: flex;
      width: 100%;
    }
    .caption{
      display: flex;
      width: 100%;
      height: 100%;
      text-align: left;
      align-content: center;
      margin: auto;
      justify-content: center;
    
      align-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 4px; // this is messy!

      background: white;
      padding: 10px;

      .caption-wrap{
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        float: left;
      }
    }


  }

}