// body.page-template-page-commisions {

  [data-barba-namespace="commissions"]{

  .commissions-thumb img {
    box-shadow: 3px -1px 5px rgba(0, 0, 0, 0.2);
  }

  .commissions-thumb {
    display: block;
    overflow: visible;

    margin: 0 38px 72px 0;
    position: relative;
    float: left;

    &:last-child {
      margin: 0 0px 72px 0;

    }

    .name {
      position: absolute;
      // position: relative;
      display: block;
      left: 0px;
      height: auto;
    }

  }
}