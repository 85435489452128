
// FONT FACE

@font-face {
font-family: 'RotisSerif';
src: url('./dist/fonts/RotisSerif.woff2') format('woff2'),
    url('./dist/fonts/RotisSerif.woff') format('woff');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'RotisSerif';
src: url('./dist/fonts/RotisSerif-Bold.woff2') format('woff2'),
    url('./dist/fonts/RotisSerif-Bold.woff') format('woff');
font-weight: bold;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'RotisSerif';
src: url('./dist/fonts/RotisSerif-Italic.woff2') format('woff2'),
    url('./dist/fonts/RotisSerif-Italic.woff') format('woff');
font-weight: normal;
font-style: italic;
font-display: swap;
}


// FAMILY
// $font-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-stack: "RotisSerif", Georgia, "Times New Roman", Times, serif; 

// //WEIGHTS
// $ultra-light-weight: 200;
// $light-weight: 300; 
// $normal-weight:400;
// $medium-weight:500;
// $heavy-weight:600; 

// @mixin light-weight{
// font-weight: 300;
// }
// @mixin normal-weight{
// font-weight: 400;
// }
// @mixin medium-weight{
// font-weight: 500;
// }
// @mixin heavy-weight{
// font-weight: 600;
// }


// $responsivetype: (
//   "1": clamp(0.1rem, 1vw + 0.4rem, 0.4rem), // was 110px
//   "2": clamp(0.1rem, 1vw + 0.3rem, 0.3rem), //  change incremental value accordinglyu //was 90px -
//   "3": clamp(0.1rem, 1vw + 0.2rem, 0.2rem), // change incremental value accordinglyu //70px,
//   "4": clamp(0.1rem, 1vw + 0.1rem, 0.1rem), // change incremental value accordingly 50px,
//    "5": clamp(0.1rem, 1vw + 0.1rem, 0.9rem), // change incremental value accordingly 28px,
// //   "6": clamp(1rem, -0.2374rem + 1.5vw, 2.875rem), // change incremental value accordingly  24px,
// //   "7": clamp(1rem, -0.2374rem + 1.4vw, 2.875rem), // change incremental value accordingly 22px,
// //   "8": clamp(1rem, -0.2374rem + 1.3vw, 2.875rem), // change incremental value accordingly 18px,
// //   "9": clamp(1rem, -0.2374rem + 1.2vw, 2.875rem), // change incremental value accordingly 16px,
// //   "10":clamp(2rem, 4vw + 1rem, 3rem) // change incremental value accordingly 12px
// );



// $responsivetype: (
//   "1": clamp(1rem, -0.2374rem + 2vw, 2.875rem), // was 110px
//   "2": clamp(1rem, -0.2374rem + 1.9vw, 2.875rem), //  change incremental value accordinglyu //was 90px -
//   "3": clamp(1rem, -0.2374rem + 1.8vw, 2.875rem), // change incremental value accordinglyu //70px,
//   "4": clamp(1rem, -0.2374rem + 1.7vw, 2.875rem), // change incremental value accordingly 50px,
//   "5": clamp(1rem, -0.2374rem + 1.6vw, 2.875rem), // change incremental value accordingly 28px,
//   "6": clamp(1rem, -0.2374rem + 1.5vw, 2.875rem), // change incremental value accordingly  24px,
//   "7": clamp(1rem, -0.2374rem + 1.4vw, 2.875rem), // change incremental value accordingly 22px,
//   "8": clamp(1rem, -0.2374rem + 1.3vw, 2.875rem), // change incremental value accordingly 18px,
//   "9": clamp(1rem, -0.2374rem + 1.2vw, 2.875rem), // change incremental value accordingly 16px,
//   "10":clamp(2rem, 4vw + 1rem, 3rem) // change incremental value accordingly 12px
// );




  /* Apply fluid typography for default zoom level (not zoomed) */
// .title {
    // font-size: clamp(2rem, 4vw + 1rem, 3rem);