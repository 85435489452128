// @import 'assets/scss/base/_variables.scss';
// @import 'assets/scss/base/_mixins.scss';
// @import '../../assets/scss/base';
@import '../01-settings/settings.colors';
@import '../01-settings/settings.designsystem';

#grid {

  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  pointer-events: none; //
  justify-items: stretch; // review
  align-items: stretch; // review

  .grid__content-item {
    // box-sizing: border-box; // review
    box-sizing: content-box;
    border: 1px solid $orange;
    display: none;
  }

  .grid__column {
    display: none;

    // &:nth-child(n+1):nth-child(-n+3) {
      //show first column only
      &:nth-child(1){
      display: flex;
    }

    // @include media("sm") {
      @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
      //only show first 3
      &:nth-child(n+1):nth-child(-n+3) {
        display: flex;
        
      }

    }

    // @include media("lg") {
      @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {


      //only show first 5
      &:nth-child(n+1):nth-child(-n+5) {
        display: flex;
      }
    }

    // @include media("xxl") {
      @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {

      //only show first 10  (all)
      &:nth-child(n+1):nth-child(-n+10) {
        display: flex;
      }
    }

    &.\--has-grid__vertline {
      // number of vertlines - as variable? - 10 in total: gutter is 2
      justify-content: center;
      align-items: flex-start;


      .grid__vertline {
        flex-grow: 1;
        opacity: 0;
        height: 100%;
        display: flex;
        border-right: 1px solid $black;
        width: auto;

        &:last-of-type {
          border-right: none;
        }

      }


    }

  }

}




#toggles {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $black;
  color: $white;
}


.grid__layer {}

// review because the grid layer components are not really needed on the mttd.studio website.
.grid__rows {}

.grid__iso {}

.grid__baselines {}

.grid__vertlines {}

.grid__vertlines-left {}

.grid__vertlines-right {}


.grid__toggles {}


// if columns grid button is active

body.grid__columns--active {

  #grid {
    opacity: 0.9;
  }

}


// if vertical lines grid button is active
body.grid__vertlines--active {

  #grid {
    // also needs to ensure this visible and vertlines are nested divs
    opacity: 0.9;

    .grid__column.\--has-grid__vertline {
      border: 0.5px solid devcolor("mttdpink"); //review (half px bit idiotic

      .grid__vertline {
        opacity: 1 !important; // use or replace class string with:  #grid .grid__column.\--has-grid__vertline .grid__vertline

      }

    }
  }

  // if both items are active
  &.grid__columns--active {

    #grid {


      .grid__column.\--has-grid__vertline {
        border: 1px solid devcolor("svsgold");
      }

    }



  }


}

// review because the grid layer components are not really needed on the mttd.studio website.

.grid__toggle {


  &[data-toggle-type="columns"] {}

  // &[data-toggle-type="rows"] {}

  &[data-toggle-type="vertlines"] {}

  // &[data-toggle-type="baselines"] {} // look to add this


}



// showcase grid (on sample page) - show dev colours when grid layers on


.grid {

  &.grid__showcase {

    .grid__content-item.content-item {
      border: 1px solid transparent;
    
    }

  }

}




body.grid__vertlines--active,
body.grid__columns--active {

  .grid {

    &.grid__showcase {

      .grid__content-item.content-item {
        border: 1px solid devcolor("mttdpink");
        color: devcolor("svsgold");  
      }

    }

  }

}