
body.page-id-9{


.post{
  width: 100%;
}


// #news .news-col {
// 	width:808px;
// 	float:left;
// }

// #news .news-col hr.all {
// 	width:762px;
// }

// #news .post {
// 	margin-bottom:44px;
// }

// #news .post img {
// 	max-width:372px;
// }

// #news .news-side {
// 	padding-top:106px;
// }

// #news .small-col li {
// 	margin-left:0px;
// 	padding-top:0px;
// }



}

// archive

.grid.news-col{
  grid-auto-flow: dense;
}