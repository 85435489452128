@import '../01-settings/_settings.designsystem';
@import '../02-tools/_tools.mixins.scss';


.grid {
  position: relative;
  height: auto;
  width: 100%;
  // padding: 0 responsivegap('xxs') 0 responsivegap('xxs'); // review - should be gutter or external gutter - maybe not even specifed on the grid componenet
  padding:0 #{map-get($responsivegap, "base")} 0 #{map-get($responsivegap, "base")};
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: #{map-get($responsivegap, "base")}; //default; // reveiw
  grid-row-gap: #{map-get($responsivegap, "base")}; //default; // reveiw
  grid-auto-flow: unset;
  //add to fix grid blowout:
  min-height: 0;
  /* new */
  min-width: 0;
  /* new; needed for FF */
  // max-width: map-get($sizes, "wrapper"); 
  box-sizing: inherit;

  @media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {

    // background-color: red;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: #{map-get($responsivegap, "xxs")}; 
    grid-row-gap: #{map-get($responsivegap, "xxs")};  
  }

  // @include media("sm") {
    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {

      // background-color: green;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-column-gap: #{map-get($responsivegap, "sm")};  
    grid-row-gap: #{map-get($responsivegap, "sm")};  
  }

  // @include media("md") {
    @media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {

    // background-color: yellow;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-column-gap: #{map-get($responsivegap, "md")}; 
    grid-row-gap: #{map-get($responsivegap, "md")};  
  }

}

.grid__content-item {
  box-sizing: content-box; //review
  display: flex;
  margin: 0;
  overflow: hidden;
}

// reiview rule - look to get rid of
.\--span-all {
  grid-column: 1/-1;
}


// create grid classes
// for each breakpoint, based on number of columns: start and end classes

@each $columnnumber,
$options in $col-breakpoint-map {

  // @include media(map-get($options, breakpoint-name)) {
  @media (min-width: map-get($options, min-grid-width)) {


    @for $i from 1 through map-get($options, total-columns) {

      .grid__content-item {

        //start grid item

        &.\--start-#{$columnnumber}--#{$i} {
          // grid-column-start: col-start #{$i}; // was this - but doesn't work oin the context of a different appraoch
          grid-column-start: #{$i};

        }

        //end grid item

        &.\--end-#{$columnnumber}--#{$i} {
          grid-column-end: #{$i+1};
        }

      }

    }

  }

}


.two-cols{

  display: block; 
  @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
  column-count: 2;
  display: block;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: #{map-get($responsivegap, "xxs")}; 
  grid-row-gap: #{map-get($responsivegap, "xxs")};  

  }
 
  p{
 
    @media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
    
    }
  }

}