body,html{
  width: 100%;
  height: auto;
  box-sizing: border-box; // review if this is problematic - but thing it should be okay to apply throughout
  display: flex;
  flex-flow: column;
  min-height: 100vh; // review - maybe set with js
}

*{
  box-sizing: border-box; // review if this is problematic - but thing it should be okay to apply throughout
}



#container {
	// // width:952px;
	width: 100%;
	//review
	// max-width: 952px;// old measurement;
	max-width: map-get($sizes, "wrapperfixed");  
	// padding:0px 24px;
	margin-left:auto;
	margin-right:auto;
	position:relative;
	display: flex;
	flex-grow: 1;
	flex-flow: column;

	.grid__gridwidth--active &{
		max-width: map-get($sizes, "wrapper"); // revuew	
	}

}

// review:
.small-col{
overflow:visible;
 li {
	list-style-type:disc;
	margin-left:12px;
	padding-top:7px;
}
}


.content {
	width: 100%;
	// overflow: hidden;
	// clear:both;
	display:block;
	// padding-top:25px;
	padding-bottom: map-get($responsivegap, "base"); 
}

.big-image {
	position:relative;
	// width:952px;
	width: 100%;
	// max-width: 952px;// old measurement;;
	// height:560px;
	// background-color:#f2f2f2;
	// review th use of t his - ultimately it should displayon pags like home
	// maybe thought it will have adverse effects in other aresa
	// margin-bottom:25px; // removed because this is theory replaced by grid item row spacing
	// overflow:hidden;
	height: auto;
    margin-bottom: map-get($responsivegap, "base");

	.grid__content-item{
		box-shadow: 1px 3px 5px rgb(0 0 0 / 20%);
	}
  }


  .image-wrap{
	width: 100%;
height: auto;
  }

img{
display: block;
width: 100%;
height: auto;
}

// this could be applied to all the "sub stuff" - as standard way of organising layouts within the columns
.\--flex-flow-column{
  display: flex;
  flex-flow: column;
}
// this could be applied to all the "sub stuff" - as standard way of organising layouts within the columns
.\--flex-flow-row{
  display: flex;
  flex-flow:row;
}