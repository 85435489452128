body {
  // normal state

  // opacity: 0; // reivew

  // #loader {
  //   opacity: 0;
  //   position: fixed;
  //   top: 0px;
  //   bottom: 0px;
  //   right: 0px;
  //   left: 0px;
  //   z-index: -1;
  //   display: flex;
  //   margin: auto;
  //   // max-height: 100vh; // reivew this

  //   #loading-logo-wrap {
  //     width: 100%;
  //     height: 100%;
  //     display: flex;
  //   }

  // }

  // #loader

}

// body

body.loading {
  // loading state - initial - butneeds to change based on var? or php
  overflow: hidden;

  // #loader {
  //   opacity: 1;
  //   z-index: 99; // higher than everything else

  //   #loading-logo-wrap {
  //     opacity: 1;

  //     #loader_logo {
  //       opacity: 1;
  //     //  width: $col_1; // reive
  //       margin: auto;
  //      }


  //   }

  // }

  // #loader

  // div,
  // span,
  // a,
  // article {
  //   opacity: 0;
  // }

  [data-barba="container"] {
    background: $grey-light;
    transition: background-color 200ms linear;

    * {
      opacity: 0;
    }

  }
}

//body.loading


//images loaded
body {


  // background-color:red;

  img:not(.logo-image) {
    opacity: 0;
  }

  &.images-loaded {
    // background-color:green;

    img {
      opacity: 1;
      transition: opacity 100ms linear;

    }

  }
}