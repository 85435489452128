#header {
	// width:952px;
	width: 100%;
	// max-width: 952px;// old measurement;;
	// margin-top: 25px;
	background-color: $grey-light;
	margin: 0 auto 0 auto;
	padding-top:#{map-get($responsivegap, "base")};
	padding-bottom: #{map-get($responsivegap, "base")};
	max-width: map-get($sizes, "wrapperfixed"); // revuew
	position: sticky;
	top: 0;
	z-index: 11;// was 6; // was 1 

	.grid__gridwidth--active &{
		max-width: map-get($sizes, "wrapper"); // revuew	
	}

	@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
		position: relative; // review - the following measurements
		padding-top:#{map-get($responsivegap, "base")};
		padding-bottom: #{map-get($responsivegap, "base")};
	}

}


.header__toggle {
	// width: 20px;
	// height: 20px;
	// background-color: green;
	margin: auto 0 auto 0; // to ensure aligns vertically centered 
	pointer-events: initial;
	display: flex;
	overflow: visible;
	margin: 1vw; // review
	flex-direction: column;
	justify-content: space-between;
	height: calc(1 * map-get($responsivegap, "base"));
	width: calc(1 * map-get($responsivegap, "base"));
	align-self: center;

	@include media("sm") {
		// table size

	}
}

.header__toggle .bar {
	height: calc(0.1 * map-get($responsivegap, "base"));
	width: 100%;
	background-color: $black;
	transition: all 100ms ease-in-out;
}

.header__toggle:hover {
	cursor: pointer;
}

.x:nth-of-type(1) {
	transition: all 100ms ease-in-out;
	transform: rotate(45deg);
	transform-origin: top left;
	width: calc(1.27 * map-get($responsivegap, "base"));

}

.x:nth-of-type(2) {
	transition: all 100ms ease-in-out;
	transform-origin: center;
	width: 0;
}

.x:nth-of-type(3) {
	transition: all 100ms ease-in-out;
	transform: rotate(-45deg);
	transform-origin: bottom left;
	width: calc(1.27 * map-get($responsivegap, "base"));
}



.search-box {
	// width:952px;
	width: 100%;
	max-width: 952px; // old measurement;;
	// margin-bottom:157 pixpx; // review this 'pixpx'
	float: left;
}

// .search-box-box {
// 	float:right;
// }

// .logo {
// 	width: 100%;
// 	height: auto;
// 	// width:305px;
// 	// height:24px;
// 	// background-image:url('https://helenyardley.com/imgs/logo.png');// review relative paths
// 	// float:left;
// 	// display:inline-block;
// }

.nav {
	// float:right;
	// display:inline-block;
	// height:24px;
	// padding-top:7px;

	display: none;
	height: auto;
	// padding-top: 7px;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
		flex-flow: row;
		justify-content: space-between;
		align-items: center;

	}

	li {
		line-height: calc(3*map-get($responsivegap, "base"));

		@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
			line-height: initial;

		}
	}
}

// .nav li {
// 	// float:left;
// 	// margin-left:27px;

// 	&.current-page {
// 		color: $orange;
// 	}
// }


.nav,
.footer-nav {

	// I guess this also no being target on IOS?
	//reive and delete commebt if resolved
	a,
	a:visited,
	a:active {
		//  display: flex;
		//   color: $deploi-white; // default - review if causing issue with say other links and "visited/active"
	}

	a:hover {
		color: $orange;
	}

}


/* current menu items */

.nav,
.footer-nav {

	li.current-menu-item>a,
	li.current_page_parent>a // for news items at - and ideally for other such as services, cients and projects

		{
		color: $orange;
	}

}


.nav__toggle {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	width: 20%;
	justify-content: end;
	cursor: pointer;

	@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
		display: none;
	}

}

.logo {
	max-width: 80%; // review;
	align-items: center;
	display: flex;
	justify-content: center;
	margin: auto;

	@media (min-width: map-get($col-breakpoint-map, "col3", "min-grid-width")) {
		max-width: 80%; // review;
	}

	@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
		max-width: 80%; // review;
		justify-content: flex-start;
		margin: 0;
	}

	@media (min-width: map-get($col-breakpoint-map, "col10", "min-grid-width")) {
		max-width: 80%; // review;
		justify-content: flex-start;
	}

	.logo-wrap{
		display: block;
		width: 100%;
	}

}

#header {

	&.\--toggle-is-on {
		box-shadow: 0 -3px 5px rgb(0 0 0 / 20%);

		.nav {
			display: flex;
		}

	}

	@media (min-width: map-get($col-breakpoint-map, "col5", "min-grid-width")) {
		box-shadow: none !important;

		.nav {
			display: flex;
		}
	}

}

//news
body.single.single-format-standard,
// active single news items
body.archive.date // active arhive news items

	{
	.menu-item-2487 a,  // header
	.menu-item-2501 a // footer
	{

		color: $orange;
	}
}

//gallery
body.single.single-gallery // active single gallery items

	{
	.menu-item-2492 a,  // header
	.menu-item-2499 a // footer
	{
		color: $orange;
	}
}